"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetMetadata = exports.Asset = exports.Watermark = exports.Transfer = exports.Stream = exports.StreamMetadata = exports.CaptionConfig = exports.AudioConfig = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.AudioConfig = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    uri: typebox_1.Type.String(),
    language: typebox_1.Type.String(),
    slug: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    assetId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Integer(),
    updatedAt: typebox_1.Type.Integer(),
});
exports.CaptionConfig = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    uri: typebox_1.Type.String(),
    language: typebox_1.Type.String(),
    slug: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    assetId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Integer(),
    updatedAt: typebox_1.Type.Integer(),
});
exports.StreamMetadata = typebox_1.Type.Union([
    // type === mux
    typebox_1.Type.Object({ muxPlaybackId: typebox_1.Type.String() }),
    // type === mediaconvert
    typebox_1.Type.Object({}),
]);
exports.Stream = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    status: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
    active: typebox_1.Type.Boolean(),
    transcodeId: typebox_1.Type.String(),
    metadata: exports.StreamMetadata,
    assetId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Integer(),
    updatedAt: typebox_1.Type.Integer(),
});
exports.Transfer = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    transferId: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    transferStatus: typebox_1.Type.String(),
    assetId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Integer(),
    updatedAt: typebox_1.Type.Integer(),
});
exports.Watermark = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    logUri: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    configurationId: typebox_1.Type.Integer(),
    strength: typebox_1.Type.Integer(),
    status: typebox_1.Type.String(),
    message: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    streamId: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    assetId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Integer(),
    updatedAt: typebox_1.Type.Integer(),
});
exports.Asset = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    uri: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
    fileType: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    fileSize: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()])),
    fileName: typebox_1.Type.String(),
    originalFileName: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    transferId: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    audioConfigs: typebox_1.Type.Array(exports.AudioConfig),
    captionConfigs: typebox_1.Type.Array(exports.CaptionConfig),
    streams: typebox_1.Type.Array(exports.Stream),
    transfer: typebox_1.Type.Optional(typebox_1.Type.Union([exports.Transfer, typebox_1.Type.Null()])),
    watermarks: typebox_1.Type.Array(exports.Watermark),
    createdAt: typebox_1.Type.Integer(),
    updatedAt: typebox_1.Type.Integer(),
    metadata: typebox_1.Type.String(),
});
exports.AssetMetadata = typebox_1.Type.Object({
    width: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Integer(), typebox_1.Type.Null()])),
    height: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Integer(), typebox_1.Type.Null()])),
    duration: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()])),
});
